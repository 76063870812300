import React from "react";
import Form from "../form/Form";
import "./../Style.css";
import TestHeading from "../test/testHeading";

const Instruct = () => {
  return (
    <React.Fragment>
      <div className=" h-fit bg-white  text-black bg-fixed w-full ">
        <div className="md:text-center mx-5 md:scroll-px-10 md:mx-40    ">
       
           <p
            className="my-5  text-[16px] md:text-justify 
                "
          >
          
            Are you seeking a meaningful connection and a lasting relationship? Understanding your Marriage Readiness Score is key to finding the right partner. This  quick and  FREE insightful  Test can help you:
          </p>

          <div className="text-[13px] md:text-justify   justify-left 2xl:mt-0">
          <div className="flex mb-5">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p> Understand your relationship patterns  </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            Identify areas for personal growth. 
            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            Enhance your ability to connect with others.
            </p>
          </div>
          
          
          
          
        </div>
          <TestHeading heading="Test  Instructions" />
         
          <br />
         
        </div>
        <div className=" md:mx-40 mx-5 text-[13px] md:text-justify  sm:mx-5  justify-left 2xl:mt-0">
          <div className="flex mb-5">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>Each question has  seven alternative responses ranging from  <strong >strongly disagree</strong> (extreme left button) to  <strong >strongly agree</strong> (extreme right button). You can choose any of the seven buttons which you feel best answers the question. </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            While taking this assessment, please <b>DO NOT </b>think of <b>work</b>, being a <b>student</b>, or any other prescribed role but rather your <b>natural preferences</b> in a romantic relationship.  
            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
           <b>Don’t focus </b> on what your partner might want, pay attention to <b>what you want now </b>and answer the questions accordingly. 
            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            For any other queries, ping on the chat icon alongside.
            </p>
          </div>
          
          
          <Form />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Instruct;
