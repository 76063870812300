// import videodesk from "../asset/video/home_video.mp4";
// import mobile_video from "../asset/video/home_mobile.mp4";


import HomeQues from "../components/home/homeQues";
import bg from "../asset/images/homebg.png";
import re1 from "../asset/images/testo/1.png";
import re2 from "../asset/images/testo/2.png";
import OfferCard from "../components/home/offerCard";
import gradientImg from '../asset/images/gradient.png'
import h1 from "../asset/images/abman.png";
import h2 from "../asset/images/abwoman.png";

import { useEffect } from 'react'
import TestHeading from "../components/test/testHeading";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import { Navigation, Autoplay } from "swiper";

// Import Swiper React components

// Import Swiper styles

import "swiper/css/navigation";
import Login from "../components/modal/Login";
import { useState } from "react";
import { get, post } from "../services/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { Dna } from "react-loader-spinner";
import Card from "../components/marriageToolkit/card";



// import required modules

export default function Home() {
  const [HomeQs, setHomeQs] = useState([])
  const [reviews, setReviews] = useState([])
  const [Topics, setTopic] = useState()
  const [Homevideo, setHomevideo] = useState([])
  const [redirect, setredirect] = useState(false)
  const navigate = useNavigate()
const baseUrl="https://knotrite.in/backend/storage/"
  const getVideo = () => {
    if (window.innerWidth > 650) {
      return (
        <video
          id="video"
          className="  h-[400px] mt-[70px] md:mt-0 md:h-[550px] "
          autoPlay
          muted
          loop
        >
          <source
            src={baseUrl+Homevideo.desktop}
            className="md:w-[100%] md:block hidden mobile ---"
          />
        </video>
      );
    } else {
      return (
        <video autoPlay muted loop>
          <source
            src={baseUrl+Homevideo.mobile}
            className="md:w-[100%] md:block hidden mobile ---"
          />
        </video>
      );
    }
  };

  const [showLogin, setShowLogin] = useState(false);
  const [banner, setBanner] = useState({})
  const [bannerText, setBannerText] = useState({})
  const [LatestPost, setLatestPost] = useState([])
  const location = useLocation()
  const [state, setState] = useState(location.state)
  let [trust, setTrust] = useState([{ content: [] }]);

  const handleClose = () => setShowLogin(false);
  function loadData() {

    get("api/get_Topbanner_content").then((res) => {

      if (res.data.success) {
        setBannerText(res.data.data[0])
      }
    })

    get("api/get_questions_and_answers")
      .then((res) => {

        if (res.data.success) {
          setHomeQs(res.data.data.reverse())
        }
        get("api/get_marriage_toolkit")
          .then((res) => {

            if (res.data.success) {



              let post = res.data.data.map((data) => {
                data.orgDate = data.date;
                data.date = data.date.split("-")

                return data
              })

              // apiData=[...res.data.data]
              // setapiData([...apiData])
              // console.log(post.splice(0,4));
              setLatestPost([...post.splice(0, 4)])
              get("api/get_topic")
                .then((res) => {

                  if (res.data.success) {
                    setTopic(res.data.data.reverse())

                  }
                })
            }
            const data =  get(`api/HomeVideo/`).then((data)=>{
              const image={}
              data.data.data.map((ele)=>{
                if(ele.name=='imageDesktopHome')
                    image.desktop=ele.image_path
                    if(ele.name=='imageMobileHome')
                        image.mobile=ele.image_path
                      setHomevideo({...image})
              })
            });
           
          })
        get("api/get_banner_content").then((res) => {

          if (res.data.success) {
            setBanner(res.data.data)
          }
          get("api/get_why_trust_us").then((res) => {

            if (res.data.success) {


              res.data.data.map((data, i) => {
                const htmlString = data.content
                const parser = new DOMParser();

                // Parse the HTML string into a document object
                const htmlDocument = parser.parseFromString(htmlString, 'text/html');

                // Get all the <li> elements
                const liElements = htmlDocument.getElementsByTagName('li');

                // Extract the text from each <li> element
                const liTextArray = Array.from(liElements).map(li => li.textContent);
                trust[i] = { ...data }
                trust[i].content = liTextArray


              })
              setTrust(trust)
            }
            get("api/get_users_reviews").then((res) => {

              if (res.data.success) {
                setReviews(res.data.data)
              }
            })
          })
        })


        // window.location.reload();
        //  onClose();

      })
      .catch((error) => {

      });


  }
  useEffect(() => {


    loadData()

    if (state?.redirect) {
      setredirect({ active: true, msg: state?.msg })
    }



  }, [state])


  return (
    <>
      {reviews.length == 0 ?
        <div className="min-h-[100vh] flex justify-center items-center ">
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          /></div>
        : <>
          <section>
            <div className="w-full flex justify-center">{getVideo()}</div>

            {redirect && (
              <div class="fixed z-10 inset-0 overflow-y-auto   border-black">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                  <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>

                  <div
                    class={
                      " borderbox inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border-2 border-solid"
                    }

                  >
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="float-right cursor-pointer" onClick={() => { navigate("/"); setredirect(false) }}>X</div>
                      <div class="sm:flex sm:items-start">

                        <div class="mt-3  sm:mt-0 sm:ml-4 sm:text-left ">


                          <p class="text-sm text-[#641662]">
                            {redirect.msg ? redirect.msg : " you have logged out of Knotrite"}
                          </p>

                          <div >

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center  ">
                      {!redirect.msg && <button
                        type="button"
                        onClick={() => {



                          setredirect(false);
                          // setShowLogin(true)
                          navigate("/login");


                        }}
                        class="cursor-pointer borderbox mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#641662] text-base font-medium text-white hover:bg-[#4c104a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        {
                          "Login"}
                      </button>}

                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-center  w-full bg-[#67365E] md:p-4 py-4">
              <h2 className="w-[90%] md:w-[70%] font-[500] md:font-[600] md:text-[20px] text-[13px]  text-center text-[#fff] leading-9">
                {/* This is not just another matrimonial or dating site. <br />
                This is where you get professional help on the most important
                decision in your life, <br />
                tying the<span className="md:text-[25px] md:font-[600]"> KNOT</span> in the right way. */}
                <div dangerouslySetInnerHTML={{ __html: bannerText.title}}></div>
              
              </h2>
            </div>
            <div className=" my-[2%] md:mt-[2%] mt-[45px] flex flex-col md:px-3 md:ml-[4%]  md:items-center md:justify-center ">

              {/* <TestHeading heading=""/> */}
              <h1 className=" size-20 text-center font-[600] md:px-[20px] md:my-5 md:mt-7 text-[#641662] text-[20px] md:text-[26px] md:mb-6 mb-10">
                {/* India's 1st Relationship & Matrimonial Assessment Service */}
                <div dangerouslySetInnerHTML={{ __html: bannerText.content}}></div>
              </h1>
              <div className="md:translate-y-[0px] translate-y-[45px]">
                {HomeQs.length > 0 && HomeQs.map((data, index) => {
                  return <HomeQues
                    mdh={index == 0 ? { marginTop: 0 } : {}}
                    img={`https://knotrite.in/backend/storage/${data.image_path}`}
                    question={
                      <p className="li break-words" dangerouslySetInnerHTML={{ __html: data.question }}>





                      </p>
                    }
                    para={
                      <>

                        <p className="w-[100%] break-words li" dangerouslySetInnerHTML={{
                          __html: data.answer,
                        }}>

                        </p>

                      </>
                    }
                    long={
                      // <p className="w-[100%] break-all">
                      //   {data.answer.substring(200,data.answer.length+1)}
                      // </p>
                      ''

                    }
                    flow={(index % 2 != 0 ? "md:flex-row-reverse" : null)}
                  />
                })}
              </div>
            </div>

            <div className=" my-[7%] md:mt-[7%] mt-[20%] flex flex-col md:px-3 md:ml-[4%]  md:items-center md:justify-center ">
              <TestHeading heading="How to go about it?" />
              <img src={gradientImg} alt="gradient" width={"100"} className="md:w-[45%] mt-[5rem] md:mr-0 mx-[5px]   w-[95%]" />
            </div>
            <div className="flex flex-col items-center md:my-[4%] my-[14%] justify-center ">
              <div className="w-[80%] flex  md:mb-0 mb-[10%] justify-center mt-8">
                <TestHeading heading="Insightful Reports: Enhance Your Relationship Journey" />
              </div>
              <div className="md:flex md:w-[50%] scale-[1.2] justify-around items-center">
                <img src={re1} className="max-w-[250px] min-h-[500px]  max-h-[500px]  min-w-[250px] mt-[5rem] mx-2" alt="" />
                <img src={re2}  className="max-w-[250px] min-h-[500px]  max-h-[500px] min-w-[250px] mt-[5rem]  mx-2" alt="" />

              </div>
            </div>
            <div className="flex justify-center relative md:mt-[14%] mt-[45%] md:mb-[4%]  ">
              <div className="md:w-[70%] ">
                <img
                  src={bg}
                  alt=""
                  width={"100%"}
                  className="md:h-[500px] h-[420px] md:block  lg:block hidden"
                />
                <img
                  src={bg}
                  alt=""
                  width={"100%"}
                  className=" h-[320px] md:hidden  lg:hidden block"
                />
              </div>
              <p className="absolute md:w-[50%] text-center text-[#FFFFFF] md:top-[20%] top-[5%] font-[400] text-[18px] md:text-[30px]" dangerouslySetInnerHTML={{ __html: banner[0]?.title }} />


              <p className="mt-5 absolute md:w-[50%] px-4 md:text-center text-[#FFFFFF] top-[28%] md:top-[45%] font-[400] text-[10px] md:text-[13px] leading-6 md:leading-8" dangerouslySetInnerHTML={{ __html: banner[0]?.content }} />
              <div className="flex md:w-[30%] w-[80%] justify-around absolute top-[68%] md:top-[70%]">
                <button
                  className="bg-[#66225e]   text-white font-[Poppins] flex justify-around items-center mr-6 py-2 md:px-5 px-2 rounded-3xl mt-6  hover:bg-pink-800 duration-500 cursor-pointer"
                  type="button"
                  onClick={() => navigate("/signup")}
                >
                  Sign up on Knotrite
                </button>
                <button
                  className="bg-[#66225e]   text-white font-[Poppins] flex justify-around items-center py-2 md:px-5 px-2 rounded-3xl mt-6  hover:bg-pink-800 duration-500 cursor-pointer"
                  type="button"
                  onClick={() => navigate("/free-test")}
                >
                  Take Free Test
                </button>
              </div>
            </div>
        
           
            <div className="flex justify-center ">
              <div className="w-[80%] flex justify-center mt-8">
                <TestHeading heading="So, What's Different?" />
              </div>
            </div>
            <div className=" w-full min-h-40  ">
              <div className="flex justify-around flex-wrap translate-y-[-60px]">
                {trust?.map((data) => {
                  return <OfferCard
                    img={`https://knotrite.in/backend/storage/${data.image_path}`}
                    text={<div dangerouslySetInnerHTML={{
                      __html: data.title,
                    }} />}
                    check={[
                      ...data.content,
                    ]}
                  />
                })}

              </div>
            </div>
            <TestHeading heading="Blog" />
            <div className="flex justify-center flex-col items-center md:mb-[5%] mb-[12%] md:w-[85%] w-[90%] mx-auto rounded-3xl md:py-10 py-4 bg-[#f0daf4d0]">
              <div className="w-full  justify-center flex md:justify-around flex-wrap ">

                <p className=" text-justify md:px-[3%] px-[30px] text-[#361235] text-[13px]  pb-6 ">In our marriage toolkit section, you will find carefully crafted articles which will make you think about some rarely discussed aspects of marriage. Read up on topics ranging from changing social mores , mental health ,legal aspects in marriage and much more.</p>

                {LatestPost.map((data, i) => {
                  return <div key={i} className="bg-white col-auto max-w-[220px] min-w-[220px]  max-h-[160px] mx-[2%] md:my-3 my-4 border-[1px] border-gray-200 hover:border-transparent hover:shadow-2xl duration-200 rounded-3xl shadow-md overflow-hidden cursor-pointer" onClick={() => navigate("/marriage-toolkit/" + data.url, { state: { title: data.title, content: data.content, img: data.image_path, date: data.orgDate, topic: Topics, latestPost: LatestPost, subHeading: data.subHeading } })}>
                    <div className="img w-full max-h-[100px] min-h-[100px] flex justify-center items-center  overflow-hidden">
                      <img src={`https://knotrite.in/backend/storage/${data.image_path}`} alt={data.image_path} />
                    </div>
                    <div className="py-2 pb-4 px-4">
                      <h2 className="font-[400] py-2 text-[12px] text-ellipsis  overflow-clip pb-4 text-[#262626B8]" dangerouslySetInnerHTML={{ __html: data.title }}>

                      </h2>


                    </div>
                  </div>
                })}


              </div>
              <p className="text-right text-[#66225e] float-right mt-4 cursor-pointer text-[13px] font-[700] w-full mr-[3%]" onClick={() => navigate("/marriage-toolkit")}>Go To Marriage ToolKit</p>
            </div>
            <TestHeading heading="They Found True Love" />

            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Navigation, Autoplay]}
              className="m"
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {reviews.map((data) => {
                return (
                  <SwiperSlide>
                    {" "}
                    <div className="flex justify-center items-center">
                      <div className="flex flex-col items-center w-[80%] justify-around">
                        <div className="img my-4 w-[100px]">
                          <img src={data.gender == "male" ? h1 : h2} alt="" className="rounded-full w-full" />
                        </div>
                        <div className="my-4">
                          {/* <svg
                        width="58"
                        height="45"
                        viewBox="0 0 58 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.70379 40.5716C2.39921 37.0617 0.625 33.1251 0.625 26.7437C0.625 15.5145 8.50787 5.45 19.9713 0.473877L22.8363 4.89496C12.1365 10.6828 10.0447 18.1935 9.2105 22.929C10.9334 22.0371 13.1888 21.7259 15.3994 21.9312C21.1872 22.467 25.7495 27.2185 25.7495 33.1251C25.7495 36.1032 24.5664 38.9594 22.4605 41.0653C20.3546 43.1712 17.4985 44.3543 14.5203 44.3543C11.0778 44.3543 7.786 42.7822 5.70379 40.5716ZM37.7871 40.5716C34.4825 37.0617 32.7083 33.1251 32.7083 26.7437C32.7083 15.5145 40.5912 5.45 52.0546 0.473877L54.9196 4.89496C44.2198 10.6828 42.128 18.1935 41.2938 22.929C43.0167 22.0371 45.2722 21.7259 47.4827 21.9312C53.2705 22.467 57.8328 27.2185 57.8328 33.1251C57.8328 36.1032 56.6497 38.9594 54.5438 41.0653C52.438 43.1712 49.5818 44.3543 46.6036 44.3543C43.1611 44.3543 39.8693 42.7822 37.7871 40.5716Z"
                          fill="#CD64A3"
                        />
                      </svg> */}
                        </div>
                        <div className="text-center text-[15px] md:w-[60%] text-[#3A3939] my-4" dangerouslySetInnerHTML={{
                          __html: data.review,
                        }}>

                        </div>
                        <div className="rating flex w-full justify-center my-4">
                          {[...Array(Number(data.rating))].map(() => {
                            return (
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.36363 12.9342L3.36775 15.7307L4.48338 10.1151L0.279419 6.22775L5.96521 5.55341L8.36363 0.354248L10.762 5.55341L16.4478 6.22775L12.2439 10.1151L13.3595 15.7307L8.36363 12.9342Z"
                                  fill="#FFBC39"
                                />
                              </svg>
                            );
                          })}
                        </div>
                        <div className="my-4 text-center">
                          <p>{data.user_name}</p>
                          <p className="text-[#959595]">Verified Customer</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </section>
          <Login onClose={handleClose} visible={showLogin} />
        </>}
    </>
  );


}
