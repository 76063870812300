import { jwtDecode } from "jwt-decode";
import { post } from "../../services/apiHandler";

export const onSuccess=(token,Nav)=>{
    // alert("called")
      // console.log("lod",data);
      console.log(token);
      
      // const toke = token.credential;


// console.log(data);
      const pass="P$x{ZGdg}J9+5-CRA,^[mD"
    const payload={
    
      token:token.credential

    }
      // SubmitForm(payload,Nav)
      post("api/google-login", {...payload})  .then((res) => {
        if (res.data.success) {

            console.log( res.data.success.token);
            
            localStorage.setItem(
              "login",
              JSON.stringify({
                login: true,
                token: res.data.success.token,
                 name:res.data.success?.name&&res.data.success?.name[0]+res.data.success?.name[1],
                 is_verified:res.data.success.is_verified,
                 fullname:res.data.success.name
              })
            );
            // window.location.reload()
            // onClose();
            
          if(localStorage.getItem("assesment")){
            post("api/assesment",  JSON.parse(localStorage.getItem("assesment"))).then((res) => {
              Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
              localStorage.removeItem("assesment")
            })
              .catch((e) => {
                Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
               
              });
          }
          else{
            Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
          }
           
          } else {
          // seterror("Credentials already registered .Please login");
          
        }
      })
      
  }

  