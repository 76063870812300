import React, { useEffect, useRef, useState } from "react";

import { post } from "../../services/apiHandler";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
import VerifyOTPModal from "./VerifyOTPModal";
import "../../asset/tostify.css";

import "react-toastify/dist/ReactToastify.css";
import info from '../../asset/images/info.png'
import { useFormik } from "formik";
import { LoginSchema, SignupSchema } from "../../schemas";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import AuthLogin from "../googleAuth/login";
// import "react-datepicker/dist/react-datepicker.css";
export default function Modal({ visible, setShowMessageModal, pendingReport, submitForm, onLogin }) {
  const Nav = useNavigate();

  const initialValues = {
    name: "",
    password: "",

    email: "",


    tmc: false
  }
 
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: SignupSchema,
    onSubmit: (value) => {
      SubmitForm(value)
    }

  })

  const [showModal, setShowModal] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [error, seterror] = useState(false);
  const [btnStatus, setBtnStatus] = useState("Sign in")
  const [showModalOTP, setShowModalOTP] = useState(false);

  const [showNewPassword1, setShowNewPassword1] = useState(false);
  const [selectedDate, setSelectedDate] = useState()


  if (!visible) return


  const handleLoginOnClose = () => setShowModal(false);

  function SubmitForm(Data) {

    setBtnStatus("loading")
    const er = Object.keys(errors)


    if (er?.length > 0) {
      seterror(errors[Object.keys(errors)[0]])
      setBtnStatus("Sign in")
      return
    }


    if (

      Data.tmc == false
    ) {
      setBtnStatus("Sign in")
      seterror("Please fill in all fields including check box");
      return;
    }
    seterror("")
    const date = new Date(selectedDate)
    let final = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    post("api/register", { ...Data, dob: final })
      .then(async (res) => {
        if (res.data.success == true) {
          setShowMessageModal(true)
        } else {
          seterror("Credentials already registered .Please login");

        }
        setBtnStatus("Sign in")
      })
      .catch((error) => {
        setBtnStatus("Sign in")
      }).finally(() => {
        setBtnStatus("Sign in")
      })
      ;
  }


  return (
    <>

      <div className={visible ? " p-0  bg-opacity-30 relative z-0 flex justify-center items-center" : "hidden"}>
        <div className=" p-0 md:m-4 z-[100] md:max-w-[310px] xl:max-w-[350px] w-[100%] max-h-[100%] md:rounded-md shadow-2xl mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
          {error != "" && (
            <div
              class="mb-4 flex  text-white justify-around absolute right-0 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
              role="alert"
            >
              {error}
              <button
                className="float-right ml-16"
                onClick={() => seterror("")}
              >
                X
              </button>
            </div>
          )}
          {/* <button className="float-right mr-2" onClick={handleOnClose}>
              X
            </button> */}
          {/* <div className="columns flex space-x-48">
              <div className=" bg-icon w-[21%] h-[50px] bg-no-repeat bg-contain ml-4 mt-1"></div>
            </div> */}

          <div>
            <form className="max-w-[400px] mx-auto py-6 md:py-1 xl:py-2 px-3">
              <h2 className="text-[#641662] text-3xl mb-4 font-[600] text-center justify-center">
                Sign up
              </h2>
              <div className="mx-auto flex justify-center"><AuthLogin seterror={seterror} setBtnStatus={setBtnStatus} /></div>
              <h2 className="text-[#641662] text-[18px] mt-4 font-[600] text-center justify-center">
                Or
              </h2>
              <h2 className="text-[#641662] text-[18px] mb-4 font-[600] text-center justify-center">
                Sign up with traditional way
              </h2>
              <div className="flex justify-between w-full gap-0">
                <div className="text-white md:my-[2px] xl:my-1 w-full">
                  <label className="text-[12px] font-[600]">Full name</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="confirmpassword"
                    className="text-pink-600 w-full placeholder:text-[12px] md:h-7 h-8 rounded pl-5"
                  />
                  <p className=" text-[10px] font-[600] text-yellow-300">{errors.name}</p>

                </div>
                {/* <div className="text-white ml-2 md:my-[2px] xl:my-1 ">
                  <label className="text-[12px] font-[600]">Surname</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Surname"
                    name="surname"
                    id="surname"
                    value={values.surname}
                    onChange={handleChange}
                onBlur={handleBlur}
                    className="text-pink-600 w-full md:h-7 h-8 rounded pl-5"
                  />
                     <p className=" text-[10px] font-[600] text-yellow-300">{errors.surname}</p>
                     <div className="text-white md:my-[2px] xl:my-1 mx-1 w-full">
                    <label className="text-[12px] font-[600]">Gender</label>
                    <br />
                    <select
                      className="text-pink-600 w-full md:h-7 h-8 rounded pl-5"
                      name="gender"
                      value={values.gender}
                     onChange={handleChange}
                onBlur={handleBlur}
                    >
                      <option value="male">male</option>
                      <option value="female">female</option>
                    </select>
                    <p className=" text-[10px] font-[600] text-yellow-300">{errors.gender}</p>
                  </div>
                </div> */}
              </div>


              {/* <div className="text-white ">
                  <label className="text-[12px] font-[600]">Phone number</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Phone"
                    name="mobile_number"
                    id="phone"
                    value={values.mobile_number}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="text-pink-600 w-full md:h-7 h-8 rounded pl-5"
                  />
                     <p className=" text-[10px] font-[600] text-yellow-300">{errors.mobile_number}</p>
                </div> */}
              <div className="text-white md:my-[2px] xl:my-1 ">
                <label className="text-[12px] font-[600]">Email</label>
                <br />
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text-pink-600 placeholder:text-[12px] w-full md:h-7 h-8 rounded pl-5"
                />
                <p className=" text-[10px] font-[600] text-yellow-300">{errors.email}</p>
              </div>


              <div className="relative text-white w-full md:my-[2px] xl:my-1 mr-2">
                <label className="text-[12px] font-[600]">Password</label>
                <br />
                <div className="relative">
                  <input
                    type={!showNewPassword1 ? "password" : "text"}
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-pink-600 placeholder:text-[12px] w-full md:h-7 h-8 rounded pl-5"
                  />

                  <div class="absolute inset-y-0 right-0 pr-1 flex items-center text-sm leading-5">
                    <span
                      onClick={() => setShowNewPassword1(!showNewPassword1)}
                      className="absolute  right-0 pr-1 text-yellow-300 cursor-pointer"
                    >
                      {showNewPassword1 ? (
                        <AiFillEye className="cursor-pointer text-lg text-yellow-300" />
                      ) : (
                        <AiFillEyeInvisible className="cursor-pointer text-lg text-yellow-300" />
                      )}
                    </span>
                  </div>
                </div>

                {/* <div class=" w-[10px] inset-y-0    flex items-center flex-row-reverse  text-sm leading-5">
                      <span
                       
                        className="absolute right-[-15%] top-[55%] w-fit  pr-8 cursor-pointer"
                      >
                       
                        {errors.password && (
                          <p>
                           
                           <a
                          href="#"
                          class="transititext-primary flex justify-center items-center text-black transition duration-150 ease-in-out hover:text-primary-600 focus:text-red active:text-primary-700 "
                          data-te-toggle="tooltip"
                          title={errors.password}
                          > <img src={info} className="w-[20px]"></img> </a>
                        </p>
                          )}
                      </span>
                      </div> */}


                {/* <div className="text-white md:my-[2px] xl:my-1 ">
                    <label className="text-[12px] font-[600]">Confirm Password</label>
                    <br />
                    
<div className="relative">
                    <input
                      type={!showNewPassword?"password":"text"}
                      placeholder="Confirm Password"
                      name="confirm_password"
                      id="password"
                      value={values.confirm_password}
                     onChange={handleChange}
                onBlur={handleBlur}
                      className="text-pink-600 relative w-full md:h-7 h-8 rounded pl-2"
                    />



                  
                    <div class="absolute inset-y-0 right-0 pr-1 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowNewPassword(!showNewPassword)}
                        className="absolute  right-0 pr-1 text-yellow-300 cursor-pointer"
                      >
                        {showNewPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-yellow-300" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-yellow-300" />
                            )}
                      </span>
                      </div>
                      </div>



                       <p className=" text-[10px] font-[600] text-yellow-300">{errors.confirm_password}</p>
                  </div> */}
              </div>
              <p className=" text-[10px] font-[600] text-yellow-300">{errors.password}</p>

              <div className="flex ml-3 my-5 mb-3 md:text-[10px] xl:text-xs text-center justify-center text-white md:whitespace-nowrap space-x-2">
                <input type="checkbox" name="tmc" value={values.tmc} checked={values.tmc} className="pr-32" id="tmc" onChange={handleChange}
                  onBlur={handleBlur} />
                <p className="w-fit">
                  By signing up you are accepting our{" "}
                  <b
                    className="cursor-pointer"
                    onClick={() => window.open('/t&c', '_blank', 'rel=noopener noreferrer')}
                  >
                    "Terms of use"
                  </b>
                </p>
              </div>
              <button
                type="button"
                onClick={() => SubmitForm(values)}
                disabled={btnStatus != "Sign in"}
                className="bg-[#480740] w-full md:h-7 h-8 flex justify-center items-center rounded-xl pl-4 text-white gap-x-4 text-center font-bold"
              >
                Sign in {btnStatus != "Sign in" ? <div class="w-4 h-4 border-2  border-dashed rounded-full animate-spin border-[white]"></div> : ""}
              </button>
              <br />
              <div className="flex">
                <p className="text-center md:justify-center  md:mt-2 text-xs w-full mt-2  text-white">
                  If you have already account you can
                  <b
                    onClick={() => {
                      Nav("/login")
                      // onLogin()
                      // handleOnClose();
                    }}
                    className=" cursor-pointer md:font-bold md:text-xs pl-1 md:text-white text-white  pt-2 text-xs font-bold"
                  >
                    LOGIN
                  </b>
                </p>
              </div>
              <br />

            </form>
          </div>
        </div>

      </div>

      <Login onClose={handleLoginOnClose} visible={showModal} />
      {otpStatus && (
        <VerifyOTPModal
          showModal={showModalOTP}
          data={{
            ...values, dob: () => {
              const date = new Date(selectedDate)
              let final = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
              return final;
            }
          }}
          submitForm={submitForm}
          pendingReport={pendingReport}
          setShowModal={setShowModalOTP}
          mobile_number={values.mobile_number}
        />
      )}
    </>
  );
}
