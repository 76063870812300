
import React, { useState } from "react";

import { post } from "../../services/apiHandler";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AuthLogin from "../googleAuth/login";


export default function Login({ visible, onClose,onSignUp,onforgot }) {
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [error, seterror] = useState(false);
  const [showNewPassword,setShowNewPassword] = useState(false);
  const[btnStatus,setBtnStatus]=useState("GET OTP")
  const [showModal, setShowModal] = useState(false)
  const Nav=useNavigate()

  

 
if(!visible)return
  const handleSubmit = (e) => {
    if(email==""||password==""){
      seterror("All Fields Are Required");
      return;
    }
    let payload_data = new FormData();
    payload_data.append("email", email);
    payload_data.append("password", password);
    
    const config = {   
      headers: { "Accept": "application/json",
      "content-type": "application/json"
    }}
    post(
        "api/login",
        payload_data,config,
      )
      .then((res) => {
        if (res.data.success == true) {
          console.log( res.data.data.token);
          
          localStorage.setItem(
            "login",
            JSON.stringify({
              login: true,
              token: res.data.data.token,
               name:res.data.data.name[0]+res.data.data.name[1],
               is_verified:res.data.data.is_verified,
               fullname:res.data.data.name
            })
          );

          if(localStorage.getItem("assesment")){
            post("api/assesment",JSON.parse(localStorage.getItem("assesment"))).then((res) => {
              Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
              localStorage.removeItem("assesment")
            })
              .catch((e) => {
                Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
               
              });
          }
          else{
            Nav("/myaccount", { state: { msg: "you have logged in successfully", redirect: true } })
          }
          // window.location.reload()
          // onClose();
         
        }
        else{
          seterror("Unregistered user or wrong login credentials. Please signup or recheck credentials");
          return;
        }
      })
      .catch((error) => {
        
      });
  };


  return (
    <>
    {/* bg-black bg-opacity-30 backdrop-blur-sm */}
    <div className={" inset-0  flex justify-center items-center "} >
      <div className=" p-2 m-4 rounded w-96 max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200" >
      {error != "" && (
              <div
                class="mb-4 flex  text-white justify-around fixed right-0 top-16 z-50 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
                role="alert"
              >
                {error}
                <button
                  className="float-right ml-16"
                  onClick={() => seterror("")}
                >
                  X
                </button>
              </div>
            )}
        {/* <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button> */}
        {/* <div className="columns flex space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-cover  ml-4 mt-4"></div>
        </div> */}
        <div>
          <form
            onSubmit={handleSubmit}
            className="max-w-[400px] mx-auto p-8 px-5"
          >
            <h2 className="text-[#641662] mb-4 text-3xl font-[600] text-center justify-center">
              Log in
            </h2>
            <div className="mx-auto flex justify-center"><AuthLogin seterror={seterror} isLogin={true} setBtnStatus={setBtnStatus}/></div>
            <div className="text-white m-3 ">
              <label>Email</label>
              <br />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-pink-600 w-full h-8 rounded pl-5 "
              />
            </div>
            <div className="text-white m-3 mb-5 ">
              <label>Password</label>
              <br />
              <div className="relative">
              <input
                type={!showNewPassword?"password":"text"}
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text-pink-600 w-full h-8 rounded pl-5"
              />
              <div class="absolute inset-y-0 right-0 pr-1 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowNewPassword(!showNewPassword)}
                        className="absolute  right-0 pr-1 text-yellow-300 cursor-pointer"
                      >
                        {showNewPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-yellow-300" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-yellow-300" />
                            )}
                      </span>
                      </div>
                      </div>
            </div>
            <p className="text-left my-4 pl-5 text-xs w-full text-white cursor-pointer" onClick={()=>{onforgot()}} >
               Forgot Password ?
              </p>
            <p
              onClick={() => handleSubmit()}
              className="cursor-pointer flex items-center bg-[#480740] w-full h-8 rounded-xl pl-4 text-white justify-center text-center font-bold "
            >
              LOGIN
            </p>

                
            <div className="flex justify-center items-center mt-2">
              <p className="text-center justify-center   text-xs w-fit text-white" onClick={()=>onSignUp()}>
                To create new account, please <b className="cursor-pointer"> SIGN UP</b>
              </p>
              <b className="md:text-xs  pl-2  sm:text-white md:text-white text-green-300 lg:text-white lg:pl-2 md:pl-2  ">
               
              </b>
            </div>
          </form>
        </div>
      </div>
      
    </div>
   
    {/* <Modal onClose={handleSignupClose} visible={showModal} /> */}
    </>
  );
}
