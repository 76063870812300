import { useNavigate, useParams } from "react-router-dom";
import { post } from "../services/apiHandler";
import { useEffect } from "react";

export default function Redirection() {
    const {id,token}=useParams()
    const Nav=useNavigate()

    const handleSubmit = () => {
        
        let payload_data = new FormData();
        payload_data.append("id", id);
        payload_data.append("token", token);
        
        const config = {   
          headers: { "Accept": "application/json",
          "content-type": "application/json"
        }}
        post(
            "api/verify-email",
            payload_data,config,
          )
          .then((res) => {
            console.log( res.data);
            if (res.data.success) {
              console.log("entered==>", res.data.success.token);
              
              localStorage.setItem(
                "login",
                JSON.stringify({
                  login: true,
                  token: res.data.success.token,
                   name:"User",
                   is_verified:res.data.success.is_verified,
                   fullname:res.data.success.name
                })
              );
             
              if(localStorage.getItem("assesment")){
                post("api/assesment", JSON.parse(localStorage.getItem("assesment"))).then((res) => {
           
            console.log(res);
            
                  localStorage.removeItem("assesment")
                })
                  .catch((e) => {
                    
                   
                  });
              }
              Nav("/myaccount", { state: { msg: "email verified successfully", redirect: true } })
              // window.location.reload()
              // onClose();
              // setTimeout(()=>{
              //   Nav("/myaccount", { state: { msg: "email verified successfully", redirect: true } })
              // },5000)
             
            }
            else{
           Nav("/login")
              return;
            }
          })
          .catch((error) => {
            
          });
      };
      useEffect(()=>{
        handleSubmit()
      },[])
    return <>
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-t from-[#f0daf4] via-[#f4eef4] to-[#67365e]">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-[#33162e] mb-4">Redirecting...</h1>
        <p className="text-lg text-[#67365e] mb-8">Please wait while we take you to the next page.</p>

        {/* Loader */}
        <div className="flex justify-center items-center space-x-2">
          <div className="w-6 h-6 bg-[#67365e] rounded-full animate-bounce"></div>
          <div className="w-6 h-6 bg-[#67365e] rounded-full animate-bounce delay-150"></div>
          <div className="w-6 h-6 bg-[#67365e] rounded-full animate-bounce delay-300"></div>
        </div>

        {/* Displaying dynamic IDs */}
      
      </div>
    </div>
    </>
}