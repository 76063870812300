import React, { useEffect, useRef, useState } from "react";
import { post } from "../../services/apiHandler";
import VerifyOtp from "./VerifyOtp";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function VerifyOTPModal({showModal,setShowModal,submitForm,pendingReport,mobile_number,data,setShowModalOTP,isAlreadyLogin}) {
  const [error, seterror] = useState(false);
  const[loading,setLoading]=useState(false)
  const [time,setTime]=useState(0)
  let inputRefs=[]
 const nav=useNavigate()

 const submitAnswers = () => {

   



  // axios
  //   .post(
  //     "https://knotrite.in/backend/api/assesment",
  //     answersData,
  //     config
  //   )
  //   .then((res) => {
  //     alert("Report Submitted Successfully. Report id is : "+ res.data.report_id);
  //   })
  //   .catch((e) => {
  
  //     alert("Action Failed. Report id is : "+ e.response.data.report_id);

  //   });
  // /free_assessment
  if (localStorage.getItem("login") && JSON.parse(localStorage.getItem("login"))?.token&&localStorage.getItem("assesment") ) {
    post("api/assesment", JSON.parse(localStorage.getItem("assesment"))).then((res) => {
      nav("/myaccount")
     
    })
      .catch((e) => {
        setLoading(false)
      
      });
  }
  else{
    nav("/myaccount")
  }
 
}
 const handleSubmit = (e) => {
  if(data.mobile_number==""||data.password==""){
    seterror("All Fields Are Required");
    return;
  }
  let payload_data = new FormData();
  payload_data.append("mobile_number", data.mobile_number);
  payload_data.append("password", data.password);
  
  const config = {   
    headers: { "Accept": "application/json",
    "content-type": "application/json"
  }}
  post(
      "api/login",
      payload_data,config,
    )
    .then((res) => {
      if (res.data.success == true) {
        
        localStorage.setItem(
          "login",
          JSON.stringify({
            login: true,
            token: res.data.data.token,
             name:res.data.data.name[0]+res.data.data.surname[0],
             fullname:res.data.data.name
          })
        );
        // window.location.reload()
        submitAnswers()
        const count=setTimeout(()=>{
          nav("/myaccount")
        },5000)
        
       
      }
      else{
        setLoading(false)
        seterror("Unregistered user or wrong login credentials. Please signup or recheck credentials");
        return;
      }
    })
    .catch((error) => {
      setLoading(false)
      
    });
};
  const countdown=function cc(){
    let act=60
    setInterval(() => {
      
      if(act==-1){  clearInterval(cc)
      clearInterval(countdown)
      }
  else
  setTime(act--)
    }, 1000); 
  
  }
  useEffect(()=>{
    resendOtp()
    setTime(60);
    countdown();
  },[])
  useEffect(()=>{
    
    return ()=>{
      clearInterval(countdown)
    }
  },[time])
    const [otp, setOtp] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
      });
      const resendOtp = (e) => {
        e?.preventDefault();
        const frm=new FormData()
        frm.append("mobile_number",mobile_number)
        frm.append("name",data?.name)
        
        post("api/resend_otp", frm)
        .then((res) => {
          
          if (res.data.success == true) {
            toast.success('OTP sent Successfully', {
              position: toast.POSITION.TOP_RIGHT
            });
            setTime(60);
            countdown();
          } else {
            toast.error(res.data.data[Object.keys(res.data.data)][0], {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setOtp({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
          })
          // window.location.reload()
        })
        .catch((error) => {
          
          setOtp({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
          })
        });
    }
    const VerifyOtp = () => {
      if(loading)return
      setLoading(true)
      let frmData=new FormData()
      frmData.append("name",data.name)
const dob=data.dob()
        post("api/verify_registration_otp", {...data,dob:dob, otp:otp.otp1+otp.otp2+otp.otp3+otp.otp4+otp.otp5+otp.otp6})
        .then((res) => {
          if (res.data.success == true) {
            if(isAlreadyLogin)
          { 
            window.location.reload()
            setShowModalOTP(false)
            
            // handleSubmit()
          }
        
            else{
              handleSubmit()
            }

            // setShowModal(false)
            // handleOnClose();
          }
          else{
            seterror(res.data.message)
            setLoading(false)
          }
         
        })
        .catch((error) => {
          setLoading(false)
        });
    }

    const handleInputChange = (e,index) => {
        setOtp({ ...otp, [e.target.name]: e.target.value });
        
        if (error != "") seterror("");
        if (e.target.value !== '' && index < 6 - 1) {
          
          inputRefs[index + 1].focus();
        }
      };
    
    
  return (
    <>
    {showModal &&
     <div className={"fixed inset-0 z-[100] bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center "}>
       {error != "" && (
              <div
                class="mb-4 flex top-10 text-white justify-around absolute right-0 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
                role="alert"
              >
                {error}
                <button
                  className="float-right ml-16"
                  onClick={() => seterror("")}
                >
                  X
                </button>
              </div>
            )}
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
    
        <button className="float-right mr-2" onClick={()=>setShowModal(false)}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[22%] h-[50px] bg-contain bg-no-repeat  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white mt-6 text-3xl font-[700] text-center justify-center">
            Verify Your Mobile Number
          </h2>
          <p className="text-white  text-[13px] font-[400] text-center justify-around">
            Please enter 6 digit code sent to {mobile_number}
          </p>
          <form className="flex flex-col items-center">
            <div className="flex justify-around items-center w-[90%] mt-9">
            

{Array.from({ length: 6 }, (_, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
           value={otp[index+1]}
           name={`otp${index+1}`}
            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mt-2 flex h-10  items-center rounded-md justify-center border p-3 w-[15%] text-[15px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"
            ref={(input) => (inputRefs[index] = input)}
              
            onChange={(e)=>handleInputChange(e,index)}
          />
        ))}
            </div>
            <div className="mb-3 mt-7  text-center w-full">
              <button type="button" onClick={()=>VerifyOtp()} className="rounded-full items-center justify-center mx-auto flex gap-2  bg-[#CD64A3] w-[85%] font-[700] text-[13px] px-5 py-2 text-base   text-white transition duration-200  hover:bg-[#9c4c7c]">
                Verify {loading&&<div class="w-4 h-4 border-2  border-dashed rounded-full animate-spin border-[white]"></div>}
              </button>
            </div>

            <div className="md:flex flex sm:flex lg:flex w-full justify-center text-center mb-5">
            {time<=0?  <p onClick={(e)=>{
                 if(time==0)
                resendOtp(e)}} className="cursor-pointer text-center justify-center font-[13px]  mt-2 pl-5 text-[13px]s w-full  text-white">
                <span
                  className="font-[700] text-[#FFBAE3] ml-1 cursor-pointer"
                >
                  Resend Code

                </span>
             
              </p> : <p className="text-white font-[500] text-center text-[12px]"> Not received OTP? You can press resend button after  {time}sec</p>}
              <b className=""></b>
            </div>
          </form>
        </div>
      </div>
    </div>
}
    </>
  )
}

export default VerifyOTPModal