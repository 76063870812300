import { GoogleLogin } from '@react-oauth/google';
import { post } from "../../services/apiHandler";
import { useNavigate } from "react-router-dom";
import { onSuccess } from './loginFunction';

export default function AuthLogin({seterror,setBtnStatus,isLogin}) {

    const Nav=useNavigate()
 



    const onFailure=(data)=>{
        console.log("lod",data);
        
    }
    return <GoogleLogin
              
                onSuccess={(data)=>onSuccess(data,Nav)}
                onFailure={onFailure}
               
                
                />
}   

// export { onSuccess};