import { useEffect, useState } from "react";

export default function HomeQues(props) {
  const [show, setShow] = useState(false);
 
  
  return (
    <>
      <div className={" justify-center md:my-0 my-20 flex md:mt-20  li break-words "} style={props.mdh}>
        <div
          className={
            "flex md:flex-row relative justify-center li flex-col items-center  md:min-w-[90%] md:max-w-[90%] duration-1000  " +
            props.flow
          }
        >
          <div
          style={{backgroundImage:`url(${props.img})`,backgroundSize:'100% 100%',backgroundPosition:'center'}}
            className={
              
              props.flow == undefined
                ? "rounded-full absolute md:left-[40px] top-[-40px]  md:top-[unset] duration-1000  md:max-w-[200px] max-w-[150px] max-h-[150px] md:max-h-[200px] md:pt-0 pt-32 w-[250px] h-[250px] flex justify-center "
                : "rounded-full absolute md:right-[40px] top-[-40px] md:top-[unset] top duration-1000  md:mr-[0%]  md:max-w-[200px] max-w-[150px] max-h-[150px] md:max-h-[200px] md:pt-0 pt-32 w-[400px] h-[400px] flex justify-center "
            }
          >
            {/* <img
              src={props.img}
              alt=""
              width={"100%"}
              className="rounded-full md:max-w-[200px] max-w-[150px] max-h-[150px] md:max-h-[200px] md:w-[200px] md:h-[200px]"
            /> */}
          </div>
          <div
            className={
              props.flow == undefined
                ? " bg-[#f0daf4d0] md:max-w-[75%] w-[90%]  md:pt-5 my-5  min-h-[200px] h-fit  rounded-3xl p-3 md:pl-[10%] pt-[100px]"
                : " bg-[#f0daf4d0] p-3 md:max-w-[75%]  w-[90%] md:pt-5 my-5 min-h-[200px] h-fit  rounded-3xl md:pl-5 md:pr-[10%] pt-[100px] "
            }
          >
            <h2 className="  text-[#641662] break-words">{props.question}</h2>
            <br />
            <p className={show?" break-words":" md:h-[75px] h-[145px] overflow-hidden li break-words"}>{props.para}</p>
          
            <button
              onClick={() => setShow(!show)}
              className="bg-[#480740]  text-white font-[Poppins] my-4 flex justify-around items-center py-2 px-5 rounded-3xl   hover:bg-pink-800 duration-500 cursor-pointer"
              type="button"
            >
              {!show ? "Read More" : "Read Less"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
