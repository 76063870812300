import { useGoogleOneTapLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { useEffect } from 'react';
import { post } from '../../services/apiHandler';
import { useNavigate } from 'react-router-dom';
import { onSuccess } from './loginFunction';

export default function OneTapLogin() {
    const Nav=useNavigate()
useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      onSuccess(credentialResponse,Nav);
    },
    onError: () => {
      console.log('Login Failed');
    },
  });


 
 
    return <>
    </>
}