import React, { useState } from "react";
import {post, get} from '../../services/apiHandler'
export default function SentEmailModal({ visible, onClose,loadData }) {

  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex py-1 space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-contain  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white mt-6 text-[15px] font-[600] text-center justify-center">
          Please verify link sent to your email.Check spam folder if not received in primary
          </h2>
          <form className="max-w-full w-full flex flex-col justify-center items-center">
           
           
            <div className="mb-3  mt-7 w-full max-w-[300px] ml-10">
              <button onClick={handleOnClose} className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[12px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]">
                Close
              </button>
            </div>
           
          </form>
        </div>
      </div>
    </div>
  );
}
