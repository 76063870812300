
import { Route, Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Homepage from './pages/FreeAssessment';
import PaidAssessment from './pages/PaidAssessment.jsx';
import MyAccount from './pages/myAccount';
import ChangePassword from './pages/changePassword';
import ForgotPassword from './components/modal/forgotPassword';
import PricingPlan from './pages/pricingPlan';
import PaymentSuccess from './pages/paymentSuccess';
import TermsCondition from './pages/terms&condition';
import Services from './pages/services';
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import PlanSummary from './pages/planSummary';
import Home from './pages/home';
import Faq from './pages/Faq';
import { GoogleOAuthProvider, useGoogleOneTapLogin } from '@react-oauth/google';
import MarriageToolkit from './pages/marriageToolkit.jsx';
import MarriageToolkitInsights from './pages/mariageToolkitInsights';
import Footer from './components/Footer';
import Navbar from './components/header/Navbar';
import { ToastContainer } from 'react-toastify';
import KnotRiteCoupleMatch from './reports/coupleMatch';
import IndividualReport from './reports/individualReport';
import KnotRiteReport from './reports/knotRiteReport';
import ProtectedRoute from './services/protetedRoute';
import { useEffect, useState } from 'react';
import { get } from './services/apiHandler';
import LoginPage from './pages/login.jsx';
import SingnUp from './pages/signup.jsx';
import AuthLogin from './components/googleAuth/login.jsx';
import { gapi } from 'gapi-script';
import OneTapLogin from './components/googleAuth/oneTaplogin.jsx';
import Redirection from './pages/redirectpage.jsx';



function App() {

  const [isFixBtnClicked, setisFixBtnClicked] = useState(false)
  const [paidAuth, setPaidAuth] = useState({ auth: false, msg: '' })
  const [freeAuth, setFreeAuth] = useState({ auth: true, msg: 'Accessed' })
  const location = useLocation()
  useEffect(() => {
    const regex = /^\/redirect\/([^\/]+)\/([^\/]+)$/;
    console.log(location.pathname);

    const match = location.pathname.match(regex);
    if (match) {
      console.log("redirecting...");

      return

    }

    else {
      console.log("nnnnnnnnn");

    }

    get("api/user")
      .then((res) => {
        try {
          const freeReport = res.data.data.reports.filter((data) => data.question_category == 4)
          const paidReport = res.data.data.reports.filter((data) => data.question_category == 5 && data.report_data != null)
          const pauseReport = res.data.data.reports.filter((data) => data.question_category == 5 && data.report_data == null)
          if (pauseReport.length > 0) setPaidAuth({ auth: true, msg: "Accessced" })
          else if (paidReport.length > 0) {
            setPaidAuth({ auth: false, msg: "You Already Taken The Test" })
  
          }
          // else if(freeReport.length==0){
          //   setPaidAuth({auth:false,msg:"Please Give Free Assesment Test First"})
  
          // }
          else {
            get("api/user").then((res) => {
              if (res.data.success) {
                const localUserDetails = JSON.parse(localStorage.getItem("login"))
                if (localUserDetails.name!=res.data.data?.name?.slice(0,2))
                {  
                  localUserDetails.name=res.data.data?.name?.slice(0,2)
                  console.log(localUserDetails);
                  
                  localStorage.setItem("login",JSON.stringify(localUserDetails))
  
                }
  
  
  
                  if (res.data.data.paid_assessment_remaining <= 0) {
                    setPaidAuth({ auth: false, msg: "This is a paid test.Please subscribe in Pricing page" })
  
                  }
                  else {
                    setPaidAuth({ auth: true, msg: "Access True" })
  
                  }
              }
            })
  
          }
          if (freeReport.length > 0) {
            setFreeAuth({ auth: false, msg: "You Already Taken The Test" })
          }
          else {
            setFreeAuth({ auth: true, msg: "Accessced" })
          }
  
        } catch (error) {
          console.log("eeeeeeeeeeeeee");
          
          localStorage.removeItem("login");
          setPaidAuth({ auth: false, msg: "Please Login to Access Page" })
        }
       

      }).catch(() => {
        // setFreeAuth({auth:false,msg:"Please Login to Access Page"})
        localStorage.removeItem("login");
        setPaidAuth({ auth: false, msg: "Please Login to Access Page" })
      })

  }, [location.pathname])

  //   useEffect(()=>{
  // if(window.location.origin!="https://staging.knotrite.in/")window.location.href="https://staging.knotrite.in/"
  //   },[location])

  // useEffect(()=>{

  // })

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: "569969858206-hr537c4sjc2keh71q61ffcbmr1ls20jf.apps.googleusercontent.com",
        scope: ""

      })
    }
    gapi.load('client:auth2', start)
  })

  return (


    <GoogleOAuthProvider clientId="569969858206-hr537c4sjc2keh71q61ffcbmr1ls20jf.apps.googleusercontent.com">
      <>
        {!localStorage.getItem("login") && <OneTapLogin />}
        <Navbar isFixBtnClicked={isFixBtnClicked} />
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Home />} />
          {paidAuth.msg && <Route path='/individualPaidTest' element={<ProtectedRoute auth={paidAuth.auth} msg={paidAuth.msg} Component={PaidAssessment} />} />}
          <Route path='/myAccount' element={<MyAccount />} />
          <Route path='/redirect/:id/:token' element={<Redirection />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/pricing/:status' element={<PricingPlan />} />
          <Route path='/pricing/' element={<PricingPlan />} />
          <Route path='/successPayment' element={<PaymentSuccess />} />
          <Route path='/successPayment/:name/:invoice' element={<PaymentSuccess />} />
          <Route path='/t&c' element={<TermsCondition />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route exact path="/plansummary" element={<PlanSummary />} />
          {freeAuth.msg && <Route path="/free-test" element={<ProtectedRoute auth={freeAuth.auth} msg={freeAuth.msg} Component={Homepage} />} />}
          <Route path="/faq" element={<Faq />} />
          <Route path="/marriage-toolkit" element={<MarriageToolkit />} />
          <Route path="/marriage-toolkit/:url" element={<MarriageToolkitInsights />} />
          <Route path="/coupleMatchReport" element={<KnotRiteCoupleMatch />} />
          <Route path="/individualReport" element={<IndividualReport />} />
          <Route path="/knotritereport" element={<KnotRiteReport />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SingnUp />} />
        </Routes>
        <Footer />

      </>
    </GoogleOAuthProvider>
  );
}

export default App;
